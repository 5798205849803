// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-archive-index-js": () => import("./../../../src/pages/archive/index.js" /* webpackChunkName: "component---src-pages-archive-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-seasons-components-bassinet-bassinet-js": () => import("./../../../src/pages/seasons/components/Bassinet/Bassinet.js" /* webpackChunkName: "component---src-pages-seasons-components-bassinet-bassinet-js" */),
  "component---src-pages-seasons-components-main-svg-main-svg-js": () => import("./../../../src/pages/seasons/components/MainSvg/MainSvg.js" /* webpackChunkName: "component---src-pages-seasons-components-main-svg-main-svg-js" */),
  "component---src-pages-seasons-components-project-text-project-text-js": () => import("./../../../src/pages/seasons/components/ProjectText/ProjectText.js" /* webpackChunkName: "component---src-pages-seasons-components-project-text-project-text-js" */),
  "component---src-pages-seasons-index-js": () => import("./../../../src/pages/seasons/index.js" /* webpackChunkName: "component---src-pages-seasons-index-js" */),
  "component---src-pages-wordle-components-box-box-js": () => import("./../../../src/pages/wordle/components/Box/Box.js" /* webpackChunkName: "component---src-pages-wordle-components-box-box-js" */),
  "component---src-pages-wordle-components-input-input-js": () => import("./../../../src/pages/wordle/components/Input/Input.js" /* webpackChunkName: "component---src-pages-wordle-components-input-input-js" */),
  "component---src-pages-wordle-index-js": () => import("./../../../src/pages/wordle/index.js" /* webpackChunkName: "component---src-pages-wordle-index-js" */)
}

